import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, pipe, UnaryFunction } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Auth, browserSessionPersistence, inMemoryPersistence, setPersistence, signInWithCustomToken, User, user } from '@angular/fire/auth';

export type AuthPipeGenerator = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => AuthPipe;
export type AuthPipe = UnaryFunction<Observable<User|null>, Observable<boolean|string|any[]>>;

export const loggedIn: AuthPipe = map(user => !!user);

@Injectable({
  providedIn: 'any'
})
export class AngularFireAuthGuard implements CanActivate {

  constructor(private router: Router, private auth: Auth) { }

  canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authPipeFactory = next.data.authGuardPipe as AuthPipeGenerator || (() => loggedIn);
    let url: string;
    return user(this.auth).pipe(
      take(1),
      switchMap(user => {
        if (user) {
          return user.getIdTokenResult()
          .then(res => {
            return res.claims?.roles
          })
          .then((roles: string[]) => {
            /*if (roles && roles.includes('driver-app.driver')) {
              return user;
            }
            throw new Error('driver role missing');*/
            return user;
          })
        }
        if (state.root.queryParams['t']) {
          
          return setPersistence(this.auth, inMemoryPersistence)
          .then(() => signInWithCustomToken(this.auth, state.root.queryParams['t']))
          .then(res => res.user.getIdTokenResult())
          .then(res => res.claims?.roles)
          .then((roles: string[]) => {
            url = state.url.split('?')[0];
            /*if (roles && roles.includes('driver')) {
              url = state.url.split('?')[0];
              return user;
            }
            throw new Error('driver role missing');*/
            return user;
          })
        }
        return of(null)
      }
        
      ),
      authPipeFactory(next, state),
      map(can => {
        if (url) {
          return this.router.parseUrl(url);
        }
        if (typeof can === 'boolean') {
          return can;
        } else if (Array.isArray(can)) {
          return this.router.createUrlTree(can, { queryParams: state.url !== '/' ? { returnUrl: state.url } : {}});
        } else {
          // TODO(EdricChan03): Add tests
          return this.router.parseUrl(can);
        }
      }),
      catchError(err =>
        this.auth.signOut().then(() => this.router.parseUrl('/login'))
      )
    );
  }

}

export const canActivate = (pipe: AuthPipeGenerator) => ({
  canActivate: [ AngularFireAuthGuard ], data: { authGuardPipe: pipe }
});


export const isNotAnonymous: AuthPipe = map(user => !!user && !user.isAnonymous);
export const idTokenResult = switchMap((user: User|null) => user ? user.getIdTokenResult() : of(null));
export const emailVerified: AuthPipe = map(user => !!user && user.emailVerified);
export const customClaims = pipe(idTokenResult, map(idTokenResult => idTokenResult ? idTokenResult.claims : []));
export const hasCustomClaim: (claim: string) => AuthPipe =
  (claim) => pipe(customClaims, map(claims =>  claims.hasOwnProperty(claim)));
export const redirectUnauthorizedTo: (redirect: string|any[]) => AuthPipe =
  (redirect) => pipe(loggedIn, map(loggedIn => loggedIn || redirect));
export const redirectLoggedInTo: (redirect: string|any[]) => AuthPipe =
  (redirect) => pipe(loggedIn, map(loggedIn => loggedIn && redirect || true));
