import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { Intercom } from 'ng-intercom';
import { tap, filter, switchMap, take, distinctUntilChanged } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectIdToken, selectUserId } from '../selectors/app.selectors';
import { logout } from 'src/app/pages/pages.actions';
import { AppState } from '../reducers';
import { HttpClient } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class IntercomEffects {
    constructor(
        private actions$: Actions,
        private intercom: Intercom,
        private store: Store<AppState>,
        private http: HttpClient
    ) { }

    authStateUserChanged$ = createEffect(() =>
        this.store.pipe(
            select(selectUserId),
            distinctUntilChanged(),
            filter(x => !!x),
            switchMap(user_id => 
                this.store.pipe(
                    select(selectIdToken),
                    filter(idToken => !!idToken),
                    take(1),
                    switchMap(idToken => 
                        this.http.post('https://drivewebapi.viggo.io/createintercomuserhash', { idempotency_key: uuidv4() }, { headers: { Authorization: `Bearer ${idToken}` } })
                    ),
                    tap((res: any) => this.intercom.boot({ user_id, user_hash: res.userHash }))
                )
                
            )
        ), {
            dispatch: false
        }
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(logout),
            tap(_ => this.intercom.shutdown())
        ), {
            dispatch: false
        }
    );

}
