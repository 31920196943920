import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Heatmap } from './heatmap.model';
import { Operation } from 'rfc6902';

export const loadHeatmaps = createAction(
  '[Heatmap/API] Load Heatmaps',
  props<{ heatmaps: Heatmap[] }>()
);

export const addHeatmap = createAction(
  '[Heatmap/API] Add Heatmap',
  props<{ heatmap: Heatmap }>()
);

export const upsertHeatmap = createAction(
  '[Heatmap/API] Upsert Heatmap',
  props<{ heatmap: Heatmap }>()
);

export const addHeatmaps = createAction(
  '[Heatmap/API] Add Heatmaps',
  props<{ heatmaps: Heatmap[] }>()
);

export const upsertHeatmaps = createAction(
  '[Heatmap/API] Upsert Heatmaps',
  props<{ heatmaps: Heatmap[] }>()
);

export const updateHeatmap = createAction(
  '[Heatmap/API] Update Heatmap',
  props<{ heatmap: Update<Heatmap> }>()
);

export const updateHeatmaps = createAction(
  '[Heatmap/API] Update Heatmaps',
  props<{ heatmaps: Update<Heatmap>[] }>()
);

export const deleteHeatmap = createAction(
  '[Heatmap/API] Delete Heatmap',
  props<{ id: string }>()
);

export const deleteHeatmaps = createAction(
  '[Heatmap/API] Delete Heatmaps',
  props<{ ids: string[] }>()
);

export const clearHeatmaps = createAction(
  '[Heatmap/API] Clear Heatmaps'
);

export const setSelectedHeatmap = createAction(
  '[Heatmap/API] Set selected Heatmap',
  props<{ id: string }>()
);

export const patchLookups = createAction(
  '[Heatmap/API] Patch lookups',
  props<{ patches: Operation[] }>()
);

export const setAreaLookups = createAction(
  '[Heatmap/API] Set area lookups',
  props<{ areas: any }>()
);

export const setMapBounds = createAction(
  '[Heatmap/API] Set map bounds',
  props<{ mapBounds: any }>()
);

export const setHistoric = createAction(
  '[Heatmap/API] Set historic',
  props<{ historic: [number, number][] }>()
);

export const loadAreaMarkers = createAction(
  '[Heatmap/API] Set area markers',
  props<{ markers: any[] }>()
);

export const updateAreaMarkers = createAction(
  '[Heatmap/API] Update area markers',
  props<{ patches: any[] }>()
);