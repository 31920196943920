import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Zone } from './zone.model';

export const loadZones = createAction(
  '[Zone/API] Load Zones', 
  props<{ zones: Zone[] }>()
);

export const addZone = createAction(
  '[Zone/API] Add Zone',
  props<{ zone: Zone }>()
);

export const upsertZone = createAction(
  '[Zone/API] Upsert Zone',
  props<{ zone: Zone }>()
);

export const addZones = createAction(
  '[Zone/API] Add Zones',
  props<{ zones: Zone[] }>()
);

export const upsertZones = createAction(
  '[Zone/API] Upsert Zones',
  props<{ zones: Zone[] }>()
);

export const updateZone = createAction(
  '[Zone/API] Update Zone',
  props<{ zone: Update<Zone> }>()
);

export const updateZones = createAction(
  '[Zone/API] Update Zones',
  props<{ zones: Update<Zone>[] }>()
);

export const deleteZone = createAction(
  '[Zone/API] Delete Zone',
  props<{ id: string }>()
);

export const deleteZones = createAction(
  '[Zone/API] Delete Zones',
  props<{ ids: string[] }>()
);

export const clearZones = createAction(
  '[Zone/API] Clear Zones'
);

export const setSelectedZoneId = createAction(
  '[Zone/API] Set selected Zone',
  props<{ id: string }>()
);

