import { Component, ChangeDetectionStrategy, OnInit, Renderer2 } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DateAdapter } from '@angular/material/core';
import { selectColorMode } from './store/selectors/app.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from './store/reducers';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  constructor(
    private swUpdate: SwUpdate,
    private dateAdapter: DateAdapter<Date>,
    private store: Store<AppState>,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.dateAdapter.setLocale('da-DK');
    this.store.pipe(
      select(selectColorMode),
      filter(colorMode => !!colorMode),
    ).subscribe(colorMode => {
      if (colorMode === 'dark') {
        this.renderer.removeClass(document.body, `style-light`);
      } else {
        this.renderer.removeClass(document.body, `style-dark`);
      }
      this.renderer.addClass(document.body, `style-${colorMode}`);
    })
    if (this.swUpdate.isEnabled) {
      setInterval(() => {
        this.checkForUpdate();
      }, 60 * 1000);
      this.checkForUpdate();

      /*this.swUpdate.versionUpdates.pipe(
        tap(console.log),
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })),
        tap(console.log),
        switchMap(() => this.swUpdate.activateUpdate()),
        tap(() => {
          console.log('reload');
          //window.location.reload();
        })
      ).subscribe();*/
    }
  }

  checkForUpdate() {
    this.swUpdate.checkForUpdate()
      .then(hasUpdate => hasUpdate && this.swUpdate.activateUpdate())
      .then(updateActivated => updateActivated && window.location.reload());
  }

}

/*@Component({
  selector: 'app-update-dialog',
  template: `
    <h1 mat-dialog-title>New update available</h1>
    <div mat-dialog-content>Do you want to load the new update?</div>
    <br/>
    <div mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close>Close</button>
      <button mat-raised-button (click)="reload()" cdkFocusInitial>Update</button>
    </div>
  `
})
export class UpdataDialogComponent {

  reload(): void {
    window.location.reload();
  }

}
*/