import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Zone } from './zone.model';
import * as ZoneActions from './zone.actions';
import { logout } from '../../pages.actions';
import { applyPatch } from 'rfc6902';
import * as clonedeep from 'lodash.clonedeep';

export const zoneFeatureKey = 'Zone';

export interface State extends EntityState<Zone> {
  // additional entities state properties
  selectedId: string | null
}

export const adapter: EntityAdapter<Zone> = createEntityAdapter<Zone>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null
});


export const reducer = createReducer(
  initialState,
  on(ZoneActions.addZone,
    (state, action) => adapter.addOne(action.zone, state)
  ),
  on(ZoneActions.upsertZone,
    (state, action) => adapter.upsertOne(action.zone, state)
  ),
  on(ZoneActions.addZones,
    (state, action) => adapter.addMany(action.zones, state)
  ),
  on(ZoneActions.upsertZones,
    (state, action) => adapter.upsertMany(action.zones, state)
  ),
  on(ZoneActions.updateZone,
    (state, action) => adapter.updateOne(action.zone, state)
  ),
  on(ZoneActions.updateZones,
    (state, action) => {
      const zones = action.zones.filter((update: any) => !!state.entities[update[0]]).map((update: any) => {
        const zone = clonedeep(state.entities[update[0]]);
        applyPatch(zone, update[1]);
        return {
          id: update[0],
          changes: zone
        };
      });
      return adapter.updateMany(zones, state);
    }
  ),
  on(ZoneActions.deleteZone,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ZoneActions.deleteZones,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ZoneActions.loadZones,
    (state, action) => adapter.setAll(action.zones, state)
  ),
  on(ZoneActions.clearZones,
    state => adapter.removeAll(state)
  ),
  on(ZoneActions.setSelectedZoneId,
    (state, action) => {
      return {...state, selectedId: state.selectedId === action.id ? null : action.id}
    }
  ),
  on(logout, () => ({ ...initialState }))
);

export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
