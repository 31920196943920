import { createAction, props } from '@ngrx/store';
import { DriverState, SocketMessage } from '../shared/models';

export const initPagesEffects = createAction(
  '[PagesEffects]: Init'
);

export const logout = createAction(
  '[PagesEffects]: Logout'
);

/*export const setHaulers = createAction(
  '[Pages] Set haulers',
  props<{ data: any[] }>()
);*/

export const setDriver = createAction(
  '[Pages] Set driver',
  props<{ data: any }>()
);

export const setVehicleTypes = createAction(
  '[Pages] Set vehicle types',
  props<{ data: any }>()
);

export const subscribeEvents = createAction(
  '[Pages] Subscribe events',
  props<{ data: string[] }>()
);

export const joinRoom = createAction(
  '[Pages] Join room',
  props<{ data: string[] }>()
);

export const leaveRoom = createAction(
  '[Pages] Leave room',
  props<{ data: string[] }>()
);

export const setPageVisible = createAction('[Pages] Set page visible');
export const setPageHidden = createAction('[Pages] Set page hidden');
export const connectEventStream = createAction('[Pages] Connect event stream');

export const unsubscribeEvents = createAction(
  '[Pages] Unsubscribe to events',
  props<{ data: string[] }>()
);

export const setDriverState = createAction(
  '[Pages] Set driver state',
  props<{ data: {
    driverState: DriverState
  } }>()
);

export const setAreaId = createAction(
  '[Pages] Set area id',
  props<{ data: string }>()
);

export const socketConnect = createAction(
  '[Pages] Socket connect'
);

export const setTms = createAction(
  '[Pages] Set TMS',
  props<{ data: number }>()
);

export const setAreas = createAction(
  '[Pages] Set areas',
  props<{ data: any }>()
);

export const setSocketStatus = createAction(
  '[Pages] Set socket status',
  props<{ data: any }>()
);

export const setDispatchTags = createAction(
  '[Pages] Set dispatch tags',
  props<{ data: any }>()
);

export const setProductFeatures = createAction(
  '[Pages] Set product features',
  props<{ data: any }>()
);

export const setEventStreamEvent = createAction(
  '[Pages] Set event stream event',
  props<{ data: {
    event: string
    data: any
  } }>()
);

export const setSocketMessageEvent = createAction(
  '[Pages] Set socket message event',
  props<{ data: SocketMessage }>()
);
