import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ChargePoint } from './charge-point.model';

export const loadChargePoints = createAction(
  '[ChargePoint/API] Load ChargePoints', 
  props<{ ChargePoints: ChargePoint[] }>()
);

export const addChargePoint = createAction(
  '[ChargePoint/API] Add ChargePoint',
  props<{ ChargePoint: ChargePoint }>()
);

export const upsertChargePoint = createAction(
  '[ChargePoint/API] Upsert ChargePoint',
  props<{ ChargePoint: ChargePoint }>()
);

export const addChargePoints = createAction(
  '[ChargePoint/API] Add ChargePoints',
  props<{ ChargePoints: ChargePoint[] }>()
);

export const upsertChargePoints = createAction(
  '[ChargePoint/API] Upsert ChargePoints',
  props<{ ChargePoints: ChargePoint[] }>()
);

export const updateChargePoint = createAction(
  '[ChargePoint/API] Update ChargePoint',
  props<{ ChargePoint: Update<ChargePoint> }>()
);

export const updateChargePoints = createAction(
  '[ChargePoint/API] Update ChargePoints',
  props<{ ChargePoints: Update<ChargePoint>[] }>()
);

export const deleteChargePoint = createAction(
  '[ChargePoint/API] Delete ChargePoint',
  props<{ id: string }>()
);

export const deleteChargePoints = createAction(
  '[ChargePoint/API] Delete ChargePoints',
  props<{ ids: string[] }>()
);

export const clearChargePoints = createAction(
  '[ChargePoint/API] Clear ChargePoints'
);

export const setSelectedChargePoint = createAction(
  '[Driver/API] Set selected ChargePoint',
  props<{ id: string }>()
);

