import { createReducer, on } from '@ngrx/store';
import firebase from 'firebase/compat';
import { logout } from 'src/app/pages/pages.actions';
import { setUser, setIdToken, setIsIdTokenCookieSet } from '../actions/app.actions';


export const authFeatureKey = 'auth';

export interface State {
  user: Partial<firebase.User>;
  idToken: string;
  cookieSet: boolean;
  bottomSheetHeight: number;
}

export const initialState: State = {
  user: null,
  idToken: null,
  cookieSet: null,
  bottomSheetHeight: 0
};

export const reducer = createReducer(
  initialState,
  on(setUser, (state, { data }) => ({ ...state, user: data.user })),
  on(setIdToken, (state, { data: { idToken } }) => ({ ...state, idToken })),
  on(setIsIdTokenCookieSet, (state, { data }) => ({ ...state, cookieSet: data })),
  on(logout, () => ({ ...initialState }))
);

