import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Heatmap } from './heatmap.model';
import * as HeatmapActions from './heatmap.actions';
import { logout } from '../../pages.actions';
import * as clonedeep from 'lodash.clonedeep';
import { applyPatch } from 'rfc6902';

export const heatmapsFeatureKey = 'heatmaps';

export interface State extends EntityState<Heatmap> {
  // additional entities state properties
  areas: any | null;
  mapBounds: any;
  markers: any[],
  selectedId: string | null;
  historic: [number, number][];
}

export const adapter: EntityAdapter<Heatmap> = createEntityAdapter<Heatmap>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  areas: null,
  mapBounds: null,
  markers: [],
  selectedId: null,
  historic: null
});

export const reducer = createReducer(
  initialState,
  on(HeatmapActions.addHeatmap,
    (state, action) => adapter.addOne(action.heatmap, state)
  ),
  on(HeatmapActions.upsertHeatmap,
    (state, action) => adapter.upsertOne(action.heatmap, state)
  ),
  on(HeatmapActions.addHeatmaps,
    (state, action) => adapter.addMany(action.heatmaps, state)
  ),
  on(HeatmapActions.upsertHeatmaps,
    (state, action) => adapter.upsertMany(action.heatmaps, state)
  ),
  on(HeatmapActions.updateHeatmap,
    (state, action) => adapter.updateOne(action.heatmap, state)
  ),
  on(HeatmapActions.updateHeatmaps,
    (state, action) => {
      const heatmaps = action.heatmaps.filter(change => !!change.changes).map((change: any) => {
        // tslint:disable-next-line: prefer-const
        const lookup = state.entities[change.id] ? clonedeep(state.entities[change.id]) : {};
        applyPatch(lookup, change.changes);
        return {
          id: change.id,
          changes: lookup
        };
      });
      return adapter.updateMany(heatmaps, state);
    }
  ),
  on(HeatmapActions.deleteHeatmap,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(HeatmapActions.deleteHeatmaps,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(HeatmapActions.loadHeatmaps,
    (state, action) => adapter.setAll(action.heatmaps, state)
  ),
  on(HeatmapActions.clearHeatmaps,
    state => adapter.removeAll(state)
  ),
  on(HeatmapActions.setSelectedHeatmap,
    (state, action) => ({...state, selectedId: action.id})
  ),
  on(HeatmapActions.setAreaLookups,
    (state, {areas}) => ({...state, areas})
  ),
  on(HeatmapActions.setMapBounds,
    (state, {mapBounds}) => ({...state, mapBounds})
  ),
  on(HeatmapActions.setHistoric,
    (state, {historic}) => ({...state, historic})
  ),
  on(HeatmapActions.loadAreaMarkers,
    (state, {markers}) => ({...state, markers})
  ),
  on(HeatmapActions.updateAreaMarkers,
    (state, {patches}) => {
      const markers = state.markers ? clonedeep(state.markers) : [];
      applyPatch(markers, patches);
      return {...state, markers}
    }
  ),
  on(logout, () => ({ ...initialState }))
);

export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
