import { createReducer, on } from '@ngrx/store';
import { setBottomSheetSize, setDarkMode, setImpactEnabled, setSoundEnabled } from '../actions/app.actions';
import * as Parser from 'ua-parser-js';
import { logout } from 'src/app/pages/pages.actions';

const parser = new Parser();

//parser.setUA('Mozilla/5.0 (X11; GNU/Linux) AppleWebKit/537.36 (KHTML, like Gecko) Chromium/79.0.3945.130 Chrome/79.0.3945.130 Safari/537.36 Tesla/2020.48.30-040912887bad')
//console.log(parser.getResult())
export const appFeatureKey = 'app';

export interface State {
  bottomSheetSize: {
    height: number,
    width: number
  },
  userAgent: any,
  darkMode: boolean,
  sound: boolean,
  impactEnabled: boolean
}

export const initialState: State = {
    bottomSheetSize: {
        height: null,
        width: null
    },
    userAgent: parser.getResult(),
    darkMode: null,
    sound: false,
    impactEnabled: false
};

export const reducer = createReducer(
  initialState,
  on(setBottomSheetSize, (state, { data: bottomSheetSize }) =>
    ({ ...state, bottomSheetSize: { ...state.bottomSheetSize, ...bottomSheetSize } })),
  on(setDarkMode, (state, { data: darkMode }) =>
    ({ ...state, darkMode })),
  on(setImpactEnabled, (state, { data: impactEnabled }) =>
    ({ ...state, impactEnabled })),
  on(setSoundEnabled, (state, { data: sound }) =>
    ({ ...state, sound })),
  on(logout, () => ({ ...initialState }))
);
