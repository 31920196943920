import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ChargeLocation } from './charge-location.model';

export const loadChargeLocations = createAction(
  '[ChargeLocation/API] Load ChargeLocations', 
  props<{ ChargeLocations: ChargeLocation[] }>()
);

export const addChargeLocation = createAction(
  '[ChargeLocation/API] Add ChargeLocation',
  props<{ ChargeLocation: ChargeLocation }>()
);

export const upsertChargeLocation = createAction(
  '[ChargeLocation/API] Upsert ChargeLocation',
  props<{ ChargeLocation: ChargeLocation }>()
);

export const addChargeLocations = createAction(
  '[ChargeLocation/API] Add ChargeLocations',
  props<{ ChargeLocations: ChargeLocation[] }>()
);

export const upsertChargeLocations = createAction(
  '[ChargeLocation/API] Upsert ChargeLocations',
  props<{ ChargeLocations: ChargeLocation[] }>()
);

export const updateChargeLocation = createAction(
  '[ChargeLocation/API] Update ChargeLocation',
  props<{ ChargeLocation: Update<ChargeLocation> }>()
);

export const updateChargeLocations = createAction(
  '[ChargeLocation/API] Update ChargeLocations',
  props<{ ChargeLocations: Update<ChargeLocation>[] }>()
);

export const deleteChargeLocation = createAction(
  '[ChargeLocation/API] Delete ChargeLocation',
  props<{ id: number }>()
);

export const deleteChargeLocations = createAction(
  '[ChargeLocation/API] Delete ChargeLocations',
  props<{ ids: number[] }>()
);

export const clearChargeLocations = createAction(
  '[ChargeLocation/API] Clear ChargeLocations'
);

export const setSelectedChargeLocation = createAction(
  '[ChargeLocation/API] Set selected ChargeLocation',
  props<{ id: number }>()
);

