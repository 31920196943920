import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as TelemetryActions from './telemetry.actions';
import { logout } from '../../pages.actions';
import { Telemetry } from './telemetry.model';

export const telemetryFeatureKey = 'telemetry';

export interface State extends EntityState<Telemetry> {
  // additional entities state properties
  selectedId: string | null;
}

export const adapter: EntityAdapter<Telemetry> = createEntityAdapter<Telemetry>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null
});

export const reducer = createReducer(
  initialState,
  on(TelemetryActions.addTelemetry,
    (state, action) => adapter.addOne(action.telemetry, state)
  ),
  on(TelemetryActions.upsertTelemetry,
    (state, action) => adapter.upsertOne(action.telemetry, state)
  ),
  on(TelemetryActions.addTelemetries,
    (state, action) => adapter.addMany(action.telemetries, state)
  ),
  on(TelemetryActions.upsertTelemetries,
    (state, action) => adapter.upsertMany(action.telemetries, state)
  ),
  on(TelemetryActions.updateTelemetry,
    (state, action) => adapter.updateOne(action.telemetry, state)
  ),
  on(TelemetryActions.updateTelemetries,
    (state, action) => adapter.updateMany(action.telemetries, state)
  ),
  on(TelemetryActions.deleteTelemetry,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(TelemetryActions.deleteTelemetries,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(TelemetryActions.loadTelemetries,
    (state, action) => adapter.setAll(action.telemetries, state)
  ),
  on(TelemetryActions.clearTelemetries,
    state => adapter.removeAll({...state, selectedId: null})
  ),
  on(TelemetryActions.setSelectedTelemetry,
    (state, action) => ({...state, selectedId: action.id})
  ),
  on(logout, () => ({ ...initialState }))
);

export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
