import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { AppOpening } from './app-opening.model';

export const loadAppOpenings = createAction(
  '[AppOpening/API] Load AppOpenings', 
  props<{ AppOpenings: AppOpening[] }>()
);

export const addAppOpening = createAction(
  '[AppOpening/API] Add AppOpening',
  props<{ AppOpening: AppOpening }>()
);

export const upsertAppOpening = createAction(
  '[AppOpening/API] Upsert AppOpening',
  props<{ AppOpening: AppOpening }>()
);

export const addAppOpenings = createAction(
  '[AppOpening/API] Add AppOpenings',
  props<{ AppOpenings: AppOpening[] }>()
);

export const upsertAppOpenings = createAction(
  '[AppOpening/API] Upsert AppOpenings',
  props<{ AppOpenings: AppOpening[] }>()
);

export const updateAppOpening = createAction(
  '[AppOpening/API] Update AppOpening',
  props<{ AppOpening: Update<AppOpening> }>()
);

export const updateAppOpenings = createAction(
  '[AppOpening/API] Update AppOpenings',
  props<{ AppOpenings: Update<AppOpening>[] }>()
);

export const deleteAppOpening = createAction(
  '[AppOpening/API] Delete AppOpening',
  props<{ id: string }>()
);

export const deleteAppOpenings = createAction(
  '[AppOpening/API] Delete AppOpenings',
  props<{ ids: string[] }>()
);

export const clearAppOpenings = createAction(
  '[AppOpening/API] Clear AppOpenings'
);

export const setSelectedAppOpening = createAction(
  '[Driver/API] Set selected AppOpening',
  props<{ id: string }>()
);

