import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Partner } from './partner.model';

export const loadPartners = createAction(
  '[Partner/API] Load Partners', 
  props<{ partners: Partner[] }>()
);

export const addPartner = createAction(
  '[Partner/API] Add Partner',
  props<{ partner: Partner }>()
);

export const upsertPartner = createAction(
  '[Partner/API] Upsert Partner',
  props<{ partner: Partner }>()
);

export const addPartners = createAction(
  '[Partner/API] Add Partners',
  props<{ partners: Partner[] }>()
);

export const upsertPartners = createAction(
  '[Partner/API] Upsert Partners',
  props<{ partners: Partner[] }>()
);

export const updatePartner = createAction(
  '[Partner/API] Update Partner',
  props<{ partner: Update<Partner> }>()
);

export const updatePartners = createAction(
  '[Partner/API] Update Partners',
  props<{ partners: Update<Partner>[] }>()
);

export const deletePartner = createAction(
  '[Partner/API] Delete Partner',
  props<{ id: string }>()
);

export const deletePartners = createAction(
  '[Partner/API] Delete Partners',
  props<{ ids: string[] }>()
);

export const clearPartners = createAction(
  '[Partner/API] Clear Partners'
);

export const setSelectedPartner = createAction(
  '[Driver/API] Set selected Partner',
  props<{ id: string }>()
);

