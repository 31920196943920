import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ZoneBooking } from './zone-booking.model';

export const loadZoneBookings = createAction(
  '[ZoneBooking/API] Load ZoneBookings', 
  props<{ zoneBookings: ZoneBooking[] }>()
);

export const addZoneBooking = createAction(
  '[ZoneBooking/API] Add ZoneBooking',
  props<{ zoneBooking: ZoneBooking }>()
);

export const upsertZoneBooking = createAction(
  '[ZoneBooking/API] Upsert ZoneBooking',
  props<{ zoneBooking: ZoneBooking }>()
);

export const addZoneBookings = createAction(
  '[ZoneBooking/API] Add ZoneBookings',
  props<{ zoneBookings: ZoneBooking[] }>()
);

export const upsertZoneBookings = createAction(
  '[ZoneBooking/API] Upsert ZoneBookings',
  props<{ zoneBookings: ZoneBooking[] }>()
);

export const updateZoneBooking = createAction(
  '[ZoneBooking/API] Update ZoneBooking',
  props<{ zoneBooking: Update<ZoneBooking> }>()
);

export const updateZoneBookings = createAction(
  '[ZoneBooking/API] Update ZoneBookings',
  props<{ zoneBookings: Update<ZoneBooking>[] }>()
);

export const deleteZoneBooking = createAction(
  '[ZoneBooking/API] Delete ZoneBooking',
  props<{ id: string }>()
);

export const deleteZoneBookings = createAction(
  '[ZoneBooking/API] Delete ZoneBookings',
  props<{ ids: string[] }>()
);

export const clearZoneBookings = createAction(
  '[ZoneBooking/API] Clear ZoneBookings'
);

export const setSelectedZoneBookingId = createAction(
  '[ZoneBooking/API] Set selected ZoneBooking',
  props<{ id: string }>()
);

