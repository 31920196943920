export enum Status {
    UNDEFINED = 0,
    REGISTERED = 1,
    ASSIGNED = 2,
    ACCEPTED = 3,
    TRIP_ON_TRIP = 4,
    IN_PROGRESS = 5,
    COMPLETED = 10,
    EXPIRED = 20,
    CANCELLED = 21
}

export enum DriverStatus {
    OFFLINE = 1,
    FREE = 2,
    BUSY = 4,
    ENROUTE = 3
}

export enum PaymentMethodType {
    PAYMENT_METHOD_TYPE_UNDEFINED = 0,
    CARD = 1,
    VEHICLE_CASH = 2,
    VEHICLE_CARD = 3,
    INVOICE = 4
}

export enum OriginType {
    ORIGIN_TYPE_UNDEFINED = 0,
    ORIGIN_TYPE_DRIVER_APP = 1,
    ORIGIN_TYPE_RIDER_APP = 2,
    ORIGIN_TYPE_FLEET_COMMANDER = 3,
    ORIGIN_TYPE_CPH_AIRPORT_TMS = 4,
    ORIGIN_TYPE_DEV_APP = 5
  }

export enum PriceType {
    PRICE_TYPE_UNDEFINED = 0,
    QUOTE = 1,
    MANUAL = 2,
    CALCULATED = 3,
    CANCELLATION = 4,
    METERED = 5
}

export enum Feature {
    'pedal_bike' = 'Bicycle',
    'luggage' = 'Luggage',
    'child_care' = 'Childseat'
}
