import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, catchError, retry, shareReplay } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { MessageType, Topic } from './enum';
import { deserialize, parseMessage, serialize } from './helpers';

@Injectable({
  providedIn: 'root',
})
export class SocketService {

    status$ = new Subject<any>()

    onAny: Subject<any> = new Subject();
    onAny$: Observable<any> = this.onAny.asObservable().pipe(
        shareReplay({refCount: true, bufferSize: 1})
    )
    
    //private topics: { [key: string]: Subscription; } = {};
    private socket$: WebSocketSubject<any>;
    private rooms: Set<String> = new Set();
    private firstConnect = true;
    private connection: Subscription;

    constructor() { 
        this.init();
    }

    createMultiplex(topic: Topic) {
        return this.socket$.multiplex(
            () => [MessageType.SUBSCRIBE, topic], // When server gets this message, it will start sending messages for 'A'...
            () => [MessageType.UNSUBSCRIBE, topic], // ...and when gets this one, it will stop.
            message => message.topic === topic // If the function returns `true` message is passed down the stream. Skipped if the function returns false.
        )
    }

    init() {
        this.socket$ = webSocket({
            url: environment.socketUrl,
            deserializer: ({ data }) => deserialize(data),
            serializer: (data: any) => serialize(data),
            openObserver: {
                next: event => {
                    this.status$.next({event: 'connected'});
                    if (!this.firstConnect && this.rooms.size) {
                        this.join([...this.rooms] as string[]);
                    }
                    this.firstConnect = false;
                }
            },
            closingObserver: {
                next: () => {}
            },
            closeObserver: {
                next: event => {
                    if (event.reason === 'auth/id-token-expired') {
                        this.status$.next({event: 'connect_error', args: {
                            message: "auth/id-token-expired",
                            code: "auth/id-token-expired"
                        } });
                    } else {
                        this.status$.next({event: 'disconnected', args: { reason: event.reason }});
                    }
                }
            },
            binaryType: 'arraybuffer' //'blob'
        }) as any;
    }

    connect() {
        if (this.isConnected()) {
            return;
        }
        this.connection = this.socket$.pipe(
            retry({count: 999999999, delay: 5000}),
            catchError(err => {
                return null;
            })
        ).subscribe({
            next: msg => {
                try { this.onAny.next(parseMessage(msg)) } catch(e) {}
                //this.onAny.next({event: msg.type, data: parseMessage(msg.payload)})
            }, // Called whenever there is a message from the server.
            error: err => {
                //console.log('error', err);
            },
            complete: () => {
                console.log('complete, will reconnect...');
                this.connect();
            } // Called when connection is closed (for whatever reason).
        });
    }
    
    isConnected() {
        return !!this.connection;
    }

    disconnect() {
        if (!this.isConnected()) {
            return;
        }
        this.connection.unsubscribe();
        this.connection = null;
    }

    logout() {
        this.leave([...this.rooms] as string[]);
        this.firstConnect = true;
        this.disconnect();
    }

    emit(event: number, data: any = {}) {
        this.socket$.next([event, data]);
    }

    join(room: string|string[]) {
        room = Array.isArray(room) ? room : [room];
        //room = room.filter(r => !this.rooms.has(r));
        room.forEach(r => this.rooms.add(r));
        if (room.length) {
            this.emit(MessageType.SUBSCRIBE, room)
        }
    }

    forceReconnect() {
        console.log('forceReconnect');
        this.disconnect();
        this.connect();
        //this.socket.close().connect();
    }

    leave(room: string|string[]) {
        room = Array.isArray(room) ? room : [room];
        //room = room.filter(r => this.rooms.has(r));
        room.forEach(r => this.rooms.delete(r));
        if (room.length) {
            this.emit(MessageType.UNSUBSCRIBE, room)
        }
    }

    //socket.emit("hello", { a: "b", c: [] });

}