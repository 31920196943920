export const environment = {
  production: true,
  firebase: {
    'viggo-drive-web-prod': {
      projectId: 'viggo-drive-web-prod',
      appId: '1:364008305319:web:d4ed71f0ff70e68842e182',
      storageBucket: 'viggo-drive-web-prod.appspot.com',
      apiKey: 'AIzaSyDr97F1VGAI5nId8r3i4ufkAVPON5gvfqk',
      authDomain: 'viggo-drive-web-prod.firebaseapp.com',
      messagingSenderId: '364008305319',
      measurementId: 'G-HQEMQGMWHE',
    },
    'viggo-digitax-app': {
      apiKey: "AIzaSyCi6BRjrY8_m0XkU1fdZNYTfP0jFUkm_Xg",
      authDomain: "viggo-digitax-app-prod.firebaseapp.com",
      databaseURL: "https://viggo-digitax-app-prod.firebaseio.com",
      projectId: "viggo-digitax-app-prod",
      storageBucket: "viggo-digitax-app-prod.appspot.com",
      messagingSenderId: "822949006331",
      appId: "1:822949006331:web:d78fd49ab684ddb9a7195e",
      measurementId: "G-HS3MNLPR4Q"
    }
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoidmlnZ28taHEiLCJhIjoiY2tlc25mN210MzFlbDJycGlhYXQyM3pmNiJ9.5eWjok66dV2ctZM6Hss_hg'
  },
  servicesUrl: 'https://services2.viggo.com',
  socketUrl: 'wss://services2.viggo.com',
  intercomAppId: 'mcha59gv',
  intercomUrl: 'https://app.intercom.com/a/apps/mcha59gv',
  faqUrl: 'https://help.viggo.com/da/collections/2426234-driver-faq'
};
