import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Partner } from './partner.model';
import * as PartnerActions from './partner.actions';
import { logout } from '../../pages.actions';

export const partnersFeatureKey = 'partners';

export interface State extends EntityState<Partner> {
  // additional entities state properties
  selectedId: string | null
}

export const adapter: EntityAdapter<Partner> = createEntityAdapter<Partner>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null
});


export const reducer = createReducer(
  initialState,
  on(PartnerActions.addPartner,
    (state, action) => adapter.addOne(action.partner, state)
  ),
  on(PartnerActions.upsertPartner,
    (state, action) => adapter.upsertOne(action.partner, state)
  ),
  on(PartnerActions.addPartners,
    (state, action) => adapter.addMany(action.partners, state)
  ),
  on(PartnerActions.upsertPartners,
    (state, action) => adapter.upsertMany(action.partners, state)
  ),
  on(PartnerActions.updatePartner,
    (state, action) => adapter.updateOne(action.partner, state)
  ),
  on(PartnerActions.updatePartners,
    (state, action) => adapter.updateMany(action.partners, state)
  ),
  on(PartnerActions.deletePartner,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PartnerActions.deletePartners,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PartnerActions.loadPartners,
    (state, action) => adapter.setAll(action.partners, state)
  ),
  on(PartnerActions.clearPartners,
    state => adapter.removeAll(state)
  ),
  on(logout, () => ({ ...initialState }))
);

export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
