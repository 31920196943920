import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Queue } from './queue.model';

export const loadQueues = createAction(
  '[Queue/API] Load Queues', 
  props<{ Queues: Queue[] }>()
);

export const addQueue = createAction(
  '[Queue/API] Add Queue',
  props<{ Queue: Queue }>()
);

export const upsertQueue = createAction(
  '[Queue/API] Upsert Queue',
  props<{ Queue: Queue }>()
);

export const addQueues = createAction(
  '[Queue/API] Add Queues',
  props<{ Queues: Queue[] }>()
);

export const upsertQueues = createAction(
  '[Queue/API] Upsert Queues',
  props<{ Queues: Queue[] }>()
);

export const updateQueue = createAction(
  '[Queue/API] Update Queue',
  props<{ Queue: Update<Queue> }>()
);

export const updateQueues = createAction(
  '[Queue/API] Update Queues',
  props<{ Queues: Update<Queue>[] }>()
);

export const deleteQueue = createAction(
  '[Queue/API] Delete Queue',
  props<{ id: string }>()
);

export const deleteQueues = createAction(
  '[Queue/API] Delete Queues',
  props<{ ids: string[] }>()
);

export const clearQueues = createAction(
  '[Queue/API] Clear Queues'
);

export const setSelectedQueue = createAction(
  '[Driver/API] Set selected Queue',
  props<{ id: string }>()
);

