import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Queue } from './queue.model';
import * as QueueActions from './queue.actions';
import { logout } from '../../pages.actions';

export const queueFeatureKey = 'Queue';

export interface State extends EntityState<Queue> {
  // additional entities state properties
  selectedId: string | null
}

export const adapter: EntityAdapter<Queue> = createEntityAdapter<Queue>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null
});


export const reducer = createReducer(
  initialState,
  on(QueueActions.addQueue,
    (state, action) => adapter.addOne(action.Queue, state)
  ),
  on(QueueActions.upsertQueue,
    (state, action) => adapter.upsertOne(action.Queue, state)
  ),
  on(QueueActions.addQueues,
    (state, action) => adapter.addMany(action.Queues, state)
  ),
  on(QueueActions.upsertQueues,
    (state, action) => adapter.upsertMany(action.Queues, state)
  ),
  on(QueueActions.updateQueue,
    (state, action) => adapter.updateOne(action.Queue, state)
  ),
  on(QueueActions.updateQueues,
    (state, action) => adapter.updateMany(action.Queues, state)
  ),
  on(QueueActions.deleteQueue,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(QueueActions.deleteQueues,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(QueueActions.loadQueues,
    (state, action) => adapter.setAll(action.Queues, state)
  ),
  on(QueueActions.clearQueues,
    state => adapter.removeAll(state)
  ),
  on(QueueActions.setSelectedQueue,
    (state, action) => ({...state, selectedId: action.id})
  ),
  on(logout, () => ({ ...initialState }))
);

export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
