import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, catchError, filter, skip, switchMap, throwError } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { refreshToken, setIsIdTokenCookieSet } from 'src/app/store/actions/app.actions';
import { selectIsIdTokenCookieSet } from 'src/app/store/selectors/app.selectors';
import { environment } from 'src/environments/environment';

@Injectable()
export class RefreshAuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<AppState>,
    ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(error => {
        if (error.status === 401 && request.url.startsWith(environment.servicesUrl)) {
          return this.reAuthenticate().pipe(
            switchMap(() => next.handle(request))
          )
        }
        return throwError(error);
      })
    )
  }

  reAuthenticate(): Observable<any> {
    this.store.dispatch(refreshToken({force: true}));
    return this.store.pipe(
        select(selectIsIdTokenCookieSet),
        skip(1),
        filter(cookieSet => !!cookieSet)
    )
  }

}