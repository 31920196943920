import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ChargeLocation } from './charge-location.model';
import * as ChargeLocationActions from './charge-location.actions';
import { logout } from '../../pages.actions';

export const chargeLocationFeatureKey = 'ChargeLocation';

export interface State extends EntityState<ChargeLocation> {
  // additional entities state properties
  selectedId: number | null
}

export const adapter: EntityAdapter<ChargeLocation> = createEntityAdapter<ChargeLocation>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null
});


export const reducer = createReducer(
  initialState,
  on(ChargeLocationActions.addChargeLocation,
    (state, action) => adapter.addOne(action.ChargeLocation, state)
  ),
  on(ChargeLocationActions.upsertChargeLocation,
    (state, action) => adapter.upsertOne(action.ChargeLocation, state)
  ),
  on(ChargeLocationActions.addChargeLocations,
    (state, action) => adapter.addMany(action.ChargeLocations, state)
  ),
  on(ChargeLocationActions.upsertChargeLocations,
    (state, action) => adapter.upsertMany(action.ChargeLocations, state)
  ),
  on(ChargeLocationActions.updateChargeLocation,
    (state, action) => adapter.updateOne(action.ChargeLocation, state)
  ),
  on(ChargeLocationActions.updateChargeLocations,
    (state, action) => adapter.updateMany(action.ChargeLocations, state)
  ),
  on(ChargeLocationActions.deleteChargeLocation,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ChargeLocationActions.deleteChargeLocations,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ChargeLocationActions.loadChargeLocations,
    (state, action) => adapter.setAll(action.ChargeLocations, state)
  ),
  on(ChargeLocationActions.clearChargeLocations,
    state => adapter.removeAll(state)
  ),
  on(ChargeLocationActions.setSelectedChargeLocation,
    (state, action) => ({...state, selectedId: action.id})
  ),
  on(logout, () => ({ ...initialState }))
);

export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
