import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromAuth from './auth.reducer';
import * as fromPages from '../../pages/pages.reducer';
import * as fromApp from './app.reducer';
import * as fromDrivers from '../../pages/store/driver/driver.reducer';
import * as fromBookings from '../../pages/store/booking/booking.reducer';
import * as fromHeatmap from '../../pages/store/heatmap/heatmap.reducer';
import * as fromPartners from '../../pages/store/partner/partner.reducer';
import * as fromTelemtries from '../../pages/store/telemetry/telemetry.reducer';

export interface AppState {
  [fromAuth.authFeatureKey]: fromAuth.State;
  [fromPages.pagesFeatureKey]: fromPages.State;
  [fromApp.appFeatureKey]: fromApp.State;
  [fromDrivers.driversFeatureKey]: fromDrivers.State;
  [fromBookings.bookingsFeatureKey]: fromBookings.State;
  [fromHeatmap.heatmapsFeatureKey]: fromHeatmap.State;
  [fromPartners.partnersFeatureKey]: fromPartners.State;
  [fromTelemtries.telemetryFeatureKey]: fromTelemtries.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [fromAuth.authFeatureKey]: fromAuth.reducer,
  [fromPages.pagesFeatureKey]: fromPages.reducer,
  [fromApp.appFeatureKey]: fromApp.reducer,
  [fromDrivers.driversFeatureKey]: fromDrivers.reducer,
  [fromBookings.bookingsFeatureKey]: fromBookings.reducer,
  [fromHeatmap.heatmapsFeatureKey]: fromHeatmap.reducer,
  [fromPartners.partnersFeatureKey]: fromPartners.reducer,
  [fromTelemtries.telemetryFeatureKey]: fromTelemtries.reducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
