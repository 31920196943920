import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ZoneBooking } from './zone-booking.model';
import * as ZoneBookingActions from './zone-booking.actions';
import { logout } from '../../pages.actions';
import { applyPatch } from 'rfc6902';
import * as clonedeep from 'lodash.clonedeep';

export const zoneBookingFeatureKey = 'ZoneBooking';

export interface State extends EntityState<ZoneBooking> {
  // additional entities state properties
  selectedId: string | null
}

export const adapter: EntityAdapter<ZoneBooking> = createEntityAdapter<ZoneBooking>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null
});


export const reducer = createReducer(
  initialState,
  on(ZoneBookingActions.addZoneBooking,
    (state, action) => adapter.addOne(action.zoneBooking, state)
  ),
  on(ZoneBookingActions.upsertZoneBooking,
    (state, action) => adapter.upsertOne(action.zoneBooking, state)
  ),
  on(ZoneBookingActions.addZoneBookings,
    (state, action) => adapter.addMany(action.zoneBookings, state)
  ),
  on(ZoneBookingActions.upsertZoneBookings,
    (state, action) => adapter.upsertMany(action.zoneBookings, state)
  ),
  on(ZoneBookingActions.updateZoneBooking,
    (state, action) => adapter.updateOne(action.zoneBooking, state)
  ),
  on(ZoneBookingActions.updateZoneBookings,
    (state, action) => {
      const zoneBookings = action.zoneBookings.filter((update: any) => !!state.entities[update[0]]).map((update: any) => {
        const zoneBooking = clonedeep(state.entities[update[0]]);
        applyPatch(zoneBooking, update[1]);
        return {
          id: update[0],
          changes: zoneBooking
        };
      });
      return adapter.updateMany(zoneBookings, state);
    }
  ),
  on(ZoneBookingActions.deleteZoneBooking,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ZoneBookingActions.deleteZoneBookings,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ZoneBookingActions.loadZoneBookings,
    (state, action) => adapter.setAll(action.zoneBookings, state)
  ),
  on(ZoneBookingActions.clearZoneBookings,
    state => adapter.removeAll(state)
  ),
  on(ZoneBookingActions.setSelectedZoneBookingId,
    (state, action) => {
      return {...state, selectedId: state.selectedId === action.id ? null : action.id}
    }
  ),
  on(logout, () => ({ ...initialState }))
);

export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];
export const getZoneBookings = (state: State) => state.ids.map(id => state.entities[id]).reduce((p, c) => ({...p, [c.queue_id]: [...(p[c.queue_id] || []), c]}), {})

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
