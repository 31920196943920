import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { tap, map, filter, switchMap, takeUntil, skip, distinctUntilChanged, distinctUntilKeyChanged, take, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { setUser, setIdToken, setIsIdTokenCookieSet, setDarkMode, refreshToken, setSoundEnabled } from '../actions/app.actions';
import { setSelectedDriver } from 'src/app/pages/store/driver/driver.actions';
import { Observable, of, timer } from 'rxjs';
import { Auth, user, idToken, AuthInstances, authState } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { signInWithCustomToken, updateEmail } from '@firebase/auth';
import { whenPageVisible } from 'src/app/shared/helpers/page-visible';
import { setSocketStatus } from 'src/app/pages/pages.actions';
import { SocketService } from 'src/app/shared/services/socket.service';

@Injectable()
export class AppEffects {

  private digitaxAppAuth: Auth;

  constructor(
    private actions$: Actions,
    private cookieService: CookieService,
    private socket: SocketService,
    public readonly auth: Auth,
    public readonly authInstances: AuthInstances,
    private http: HttpClient
  ) {
    this.digitaxAppAuth = this.authInstances.find(x => x.name === 'viggo-digitax-app');
  }

  detectDarkMode$ = createEffect(() =>
    new Observable(observer => {
      const colorScheme = this.cookieService.get('color-scheme');
      if (colorScheme) {
        observer.next(colorScheme === 'dark');
      } else {
        observer.next(window?.matchMedia('(prefers-color-scheme: dark)').matches)
      }
      window?.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        this.cookieService.remove('color-scheme');
        observer.next(e.matches);
      });
    }).pipe(
      map((darkMode: boolean) => setDarkMode({ data: darkMode }))
    )
  )

  detectSoundMode$ = createEffect(() =>
    new Observable(observer => {
      const soundEnabled = this.cookieService.get('sound-enabled');
      observer.next(soundEnabled === 'true');
    }).pipe(
      map((soundEnabled: boolean) => setSoundEnabled({ data: soundEnabled }))
    )
  )

  onSetIdToken$ = createEffect(() =>
      this.actions$.pipe(
        ofType(setIsIdTokenCookieSet),
        filter(action => !!action.data),
        tap(() => this.socket.connect()),
        withLatestFrom(user(this.auth)),
        map(([_, user]) => user.uid),
        distinctUntilChanged(),
        switchMap(() => this.http.get(`${environment.servicesUrl}/digitax/auth`, { withCredentials: true })),
        switchMap((token: string) => signInWithCustomToken(this.digitaxAppAuth, token))
      ), {
        dispatch: false
      }
  );

  onAuthStateSignOut = createEffect(() =>
    authState(this.auth).pipe(
      filter(auth => !auth),
      tap(() => this.digitaxAppAuth.signOut()),
      switchMap(() => [
        setUser({ data: { user: null }}),
        setSelectedDriver({ id: null })
      ])
    )
  )

  onAuthStateSignIn = createEffect(() =>
    authState(this.auth).pipe(
      filter(auth => !!auth),
      switchMap(auth => auth.getIdTokenResult()),
      map(res => res.claims),
      switchMap(claims => [
        setUser({ data: { user: { email: claims.email, uid: claims.user_id } }}),
        //setSelectedDriver({ id: claims.user_id })
      ])
    )
  )

  onIdToken$ = createEffect(() =>
    idToken(this.auth).pipe(
      map(idToken => setIdToken({ data: { idToken } })),
    )
  );

  /*onUser$ = createEffect(() =>
    user(this.auth).pipe(
      tap(onUser => console.log('onUser', onUser)),
      map(user => setUser({ data: { user: user ? { email: user.email, uid: user.uid } : null }}),)
    )
  );*/
  
  onConnectError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSocketStatus),
      map(action => action.data),
      filter(data => data.event === 'connect_error' && data.args?.code === 'auth/id-token-expired'),
      switchMap(() => {
        Promise.all([
          this.auth.currentUser.getIdToken(true),
          this.digitaxAppAuth.currentUser.getIdToken(true)
        ])
        return this.actions$.pipe(
          ofType(setIsIdTokenCookieSet),
          filter(isSet => !!isSet),
          take(1)
        )
      }),
      tap(() => this.socket.connect())
    ), { dispatch: false }
  )

  checkRefreshIdToken$ = createEffect(() => 
    this.actions$.pipe(
      ofType(setIdToken),
      map(action => !!action.data.idToken),
      switchMap(hasToken => 
        of(hasToken).pipe(
          distinctUntilChanged(),
          whenPageVisible(),
          skip(1)
        )
      ),
      filter(hasToken => !!hasToken),
      map(() => refreshToken({force: false}))
    )
  )

  onRefreshIdToken$ = createEffect(() =>
    idToken(this.auth).pipe(
      switchMap(() => 
        timer(1000 * 60 * 55).pipe(
          takeUntil(authState(this.auth).pipe(
            filter(authState => !authState)
          ))
        )
      ),
      map(() => refreshToken({force: false}))
    )
  )

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshToken),
      switchMap(action => Promise.all([
        this.auth.currentUser.getIdToken(!!action.force),
        this.digitaxAppAuth.currentUser.getIdToken(!!action.force)
      ]))
    ), {
      dispatch: false
    }
  )

  setAuthCookie$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setIdToken),
      map(action => action.data.idToken),
      tap(idToken => {
        if (idToken) {
          this.cookieService.put('idToken', idToken, environment.production ? {
            secure: true,
            httpOnly: false,
            domain: 'viggo.com',
            sameSite: 'strict'
          } as CookieOptions : {});
        } else {
          this.cookieService.remove('idToken');
        }
      }),
      map(idToken => setIsIdTokenCookieSet({data: !!idToken }))
    )
  );

}