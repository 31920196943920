import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Driver } from './driver.model';
import * as DriverActions from './driver.actions';
import { logout } from '../../pages.actions';

export const driversFeatureKey = 'drivers';

export interface State extends EntityState<Driver> {
  // additional entities state properties
  selectedId: string | null;
  showOnlyAvailable: boolean | null;
  showOffline: boolean | null;
}

export const adapter: EntityAdapter<Driver> = createEntityAdapter<Driver>();

const getCookie = function(name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null,
  showOnlyAvailable: null,
  showOffline: getCookie('showOffline') === 'true'
});

export const reducer = createReducer(
  initialState,
  on(DriverActions.addDriver,
    (state, action) => adapter.addOne(action.driver, state)
  ),
  on(DriverActions.upsertDriver,
    (state, action) => adapter.upsertOne(action.driver, state)
  ),
  on(DriverActions.addDrivers,
    (state, action) => adapter.addMany(action.drivers, state)
  ),
  on(DriverActions.upsertDrivers,
    (state, action) => adapter.upsertMany(action.drivers, state)
  ),
  on(DriverActions.updateDriver,
    (state, action) => adapter.updateOne(action.driver, state)
  ),
  on(DriverActions.updateDrivers,
    (state, action) => adapter.updateMany(action.drivers, state)
  ),
  on(DriverActions.deleteDriver,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DriverActions.deleteDrivers,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DriverActions.loadDrivers,
    (state, action) => adapter.setAll(action.drivers, state)
  ),
  on(DriverActions.clearDrivers,
    state => adapter.removeAll({...state, selectedId: null})
  ),
  on(DriverActions.setSelectedDriver,
    (state, action) => ({...state, selectedId: action.id})
  ),
  on(DriverActions.setShowOnlyAvailable,
    (state, action) => ({...state, showOnlyAvailable: action.showOnlyAvailable})
  ),
  on(DriverActions.setShowOffline,
    (state, action) => ({...state, showOffline: action.showOffline})
  ),
  on(logout, () => ({ ...initialState }))
);

export const getShowOfflineDrivers = (state: State) => state.showOffline;
export const getShowOnlyAvailableDrivers = (state: State) => state.showOnlyAvailable;
export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
