import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AppOpening } from './app-opening.model';
import * as AppOpeningActions from './app-opening.actions';
import { logout } from '../../pages.actions';

export const AppOpeningFeatureKey = 'AppOpening';

export interface State extends EntityState<AppOpening> {
  // additional entities state properties
  selectedId: string | null
}

export const adapter: EntityAdapter<AppOpening> = createEntityAdapter<AppOpening>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null
});


export const reducer = createReducer(
  initialState,
  on(AppOpeningActions.addAppOpening,
    (state, action) => adapter.addOne(action.AppOpening, state)
  ),
  on(AppOpeningActions.upsertAppOpening,
    (state, action) => adapter.upsertOne(action.AppOpening, state)
  ),
  on(AppOpeningActions.addAppOpenings,
    (state, action) => adapter.addMany(action.AppOpenings, state)
  ),
  on(AppOpeningActions.upsertAppOpenings,
    (state, action) => adapter.upsertMany(action.AppOpenings, state)
  ),
  on(AppOpeningActions.updateAppOpening,
    (state, action) => adapter.updateOne(action.AppOpening, state)
  ),
  on(AppOpeningActions.updateAppOpenings,
    (state, action) => adapter.updateMany(action.AppOpenings, state)
  ),
  on(AppOpeningActions.deleteAppOpening,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AppOpeningActions.deleteAppOpenings,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AppOpeningActions.loadAppOpenings,
    (state, action) => adapter.setAll(action.AppOpenings, state)
  ),
  on(AppOpeningActions.clearAppOpenings,
    state => adapter.removeAll(state)
  ),
  on(AppOpeningActions.setSelectedAppOpening,
    (state, action) => ({...state, selectedId: action.id})
  ),
  on(logout, () => ({ ...initialState }))
);

export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
