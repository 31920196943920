import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ChargePoint } from './charge-point.model';
import * as ChargePointActions from './charge-point.actions';
import { logout } from '../../pages.actions';

export const chargePointFeatureKey = 'ChargePoint';

export interface State extends EntityState<ChargePoint> {
  // additional entities state properties
  selectedId: string | null
}

export const adapter: EntityAdapter<ChargePoint> = createEntityAdapter<ChargePoint>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null
});


export const reducer = createReducer(
  initialState,
  on(ChargePointActions.addChargePoint,
    (state, action) => adapter.addOne(action.ChargePoint, state)
  ),
  on(ChargePointActions.upsertChargePoint,
    (state, action) => adapter.upsertOne(action.ChargePoint, state)
  ),
  on(ChargePointActions.addChargePoints,
    (state, action) => adapter.addMany(action.ChargePoints, state)
  ),
  on(ChargePointActions.upsertChargePoints,
    (state, action) => adapter.upsertMany(action.ChargePoints, state)
  ),
  on(ChargePointActions.updateChargePoint,
    (state, action) => adapter.updateOne(action.ChargePoint, state)
  ),
  on(ChargePointActions.updateChargePoints,
    (state, action) => adapter.updateMany(action.ChargePoints, state)
  ),
  on(ChargePointActions.deleteChargePoint,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ChargePointActions.deleteChargePoints,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ChargePointActions.loadChargePoints,
    (state, action) => adapter.setAll(action.ChargePoints, state)
  ),
  on(ChargePointActions.clearChargePoints,
    state => adapter.removeAll(state)
  ),
  on(ChargePointActions.setSelectedChargePoint,
    (state, action) => ({...state, selectedId: action.id})
  ),
  on(logout, () => ({ ...initialState }))
);

export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
