import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Telemetry } from './telemetry.model';

export const loadTelemetries = createAction(
  '[Telemetry/API] Load Telemetries',
  props<{ telemetries: Telemetry[] }>()
);

export const addTelemetry = createAction(
  '[Telemetry/API] Add Telemetry',
  props<{ telemetry: Telemetry }>()
);

export const upsertTelemetry = createAction(
  '[Telemetry/API] Upsert Telemetry',
  props<{ telemetry: Telemetry }>()
);

export const addTelemetries = createAction(
  '[Telemetry/API] Add Telemetries',
  props<{ telemetries: Telemetry[] }>()
);

export const upsertTelemetries = createAction(
  '[Telemetry/API] Upsert Telemetries',
  props<{ telemetries: Telemetry[] }>()
);

export const updateTelemetry = createAction(
  '[Telemetry/API] Update Telemetry',
  props<{ telemetry: Update<Telemetry> }>()
);

export const updateTelemetries = createAction(
  '[Telemetry/API] Update Telemetries',
  props<{ telemetries: Update<Telemetry>[] }>()
);

export const deleteTelemetry = createAction(
  '[Telemetry/API] Delete Telemetry',
  props<{ id: string }>()
);

export const deleteTelemetries = createAction(
  '[Telemetry/API] Delete Telemetries',
  props<{ ids: string[] }>()
);

export const clearTelemetries = createAction(
  '[Telemetry/API] Clear Telemetries'
);

export const setSelectedTelemetry = createAction(
  '[Telemetry/API] Set selected Telemetry',
  props<{ id: string }>()
);
