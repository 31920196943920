import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Booking } from './booking.model';
import * as BookingActions from './booking.actions';
import { logout } from '../../pages.actions';
import { applyPatch } from 'rfc6902';
import * as clonedeep from 'lodash.clonedeep';

export const bookingsFeatureKey = 'bookings';

export interface State extends EntityState<Booking> {
  // additional entities state properties
  selectedId: string | null;
  trip: any;
}

export const adapter: EntityAdapter<Booking> = createEntityAdapter<Booking>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null,
  trip: null
});


export const reducer = createReducer(
  initialState,
  on(BookingActions.addBooking,
    (state, action) => adapter.addOne(action.booking, state)
  ),
  on(BookingActions.upsertBooking,
    (state, action) => adapter.upsertOne(action.booking, state)
  ),
  on(BookingActions.addBookings,
    (state, action) => adapter.addMany(action.bookings, state)
  ),
  on(BookingActions.upsertBookings,
    (state, action) => adapter.upsertMany(action.bookings, state)
  ),
  on(BookingActions.updateBooking,
    (state, action) => {
      if (!!state.entities[action.booking.id]) {
        const booking = clonedeep(state.entities[action.booking.id]);
        applyPatch(booking, <any>action.booking.changes);
        return adapter.updateOne(<any>{
          id: action.booking.id,
          changes: booking
        }, state);
      }
      return state;
    }
  ),
  on(BookingActions.updateBookings,
    (state, action) => {
      const bookings = action.bookings.filter(change => !!change.changes).filter((change: any) => !!state.entities[change.id]).map((change: any) => {
        const booking = clonedeep(state.entities[change.id]);
        applyPatch(booking, change.changes);
        return {
          id: change.id,
          changes: booking
        };
      });
      return adapter.updateMany(bookings, state);
    }
  ),
  on(BookingActions.deleteBooking,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(BookingActions.deleteBookings,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(BookingActions.loadBookings,
    (state, action) => adapter.setAll(action.bookings, state)
  ),
  on(BookingActions.clearBookings,
    state => adapter.removeAll(state)
  ),
  on(BookingActions.setSelectedBooking,
    (state, action) => ({...state, selectedId: action.id})
  ),
  on(BookingActions.setCurrentTrip,
    (state, action) => ({...state, trip: action.trip})
  ),
  on(logout, () => ({ ...initialState }))
);

export const getSelectedId = (state: State) => state.selectedId;
export const getSelected = (state: State) => state.entities[state.selectedId];

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
