import { createAction, props } from '@ngrx/store';
import firebase from 'firebase/compat';

export const setUser = createAction(
  '[App] Set user',
  props<{ data: {
    user: Partial<firebase.User>
  }}>()
);

export const setIdToken = createAction(
  '[App] Set id token',
  props<{ data: {
    idToken: string
  }}>()
);

export const refreshToken = createAction(
  '[App] Refresh token',
  props<{ force?: boolean }>()
);

export const setDarkMode = createAction(
  '[App] Set dark mode',
  props<{ data: boolean }>()
)

export const setImpactEnabled = createAction(
  '[App] Set impact mode',
  props<{ data: boolean }>()
)

export const setSoundEnabled = createAction(
  '[App] Set sound mode',
  props<{ data: boolean }>()
)

export const setIsIdTokenCookieSet = createAction(
  '[App] Set is id token cookie set',
  props<{ data: boolean }>()
);

export const setBottomSheetSize = createAction(
  '[App] Set bottom sheet height',
  props<{ data: {
    x?: number,
    y?: number,
    height?: number,
    width?: number
  } }>()
);
