import { gunzipSync, gzipSync } from "fflate";
import { MessageType } from "./enum";
import { ProtoMessage } from './compiled.js';

const MESSAGE_TYPE_MAX_LENGTH = `${Object.values(MessageType).pop()}`.length;

export const serialize = ([type, payload]: [type: number, payload: ArrayBuffer|string|any]) : ArrayBufferLike => {
    const topicBuffer = new TextEncoder().encode(`${type}`.padEnd(MESSAGE_TYPE_MAX_LENGTH, " "));
    if (!payload.byteLength) {
        try { payload = JSON.stringify(payload); } catch (e) {}
        payload = gzipSync(new TextEncoder().encode(payload));
    }
    const combinedBuffer = new Uint8Array(topicBuffer.byteLength + payload.byteLength);
    combinedBuffer.set(topicBuffer, 0);
    combinedBuffer.set(payload, topicBuffer.byteLength);
    return combinedBuffer.buffer;    
}

/*export const serialize = ([topic, payload]: [topic: number, payload: ArrayBuffer|string|any]) : ArrayBufferLike => {
    const topicBuffer = new TextEncoder().encode(`${topic}`.padEnd(MESSAGE_TYPE_MAX_LENGTH, " "));
    if (payload.byteLength) {
        payload = new Uint8Array(payload);
    } else {
        try {
            payload = JSON.stringify(payload);
        } catch (e) {}
        payload = new TextEncoder().encode(payload);
    }
    const combinedBuffer = new Uint8Array(topicBuffer.byteLength + payload.byteLength);
    combinedBuffer.set(topicBuffer, 0);
    combinedBuffer.set(new Uint8Array(payload), topicBuffer.byteLength);
    return combinedBuffer.buffer;    
}*/

export const deserialize = (data: ArrayBuffer) => {
    const part = new TextDecoder().decode(data.slice(0, MESSAGE_TYPE_MAX_LENGTH) as ArrayBuffer);
    return {
        type: parseInt(part),
        payload: gunzipSync(new Uint8Array(data.slice(part.length)))
    }
}

export const parseMessage = ({type, payload}) => {
    //console.log(MessageType[type])
    if (!!ProtoMessage[MessageType[type]]) {
        return {event: type, data: ProtoMessage[MessageType[type]].decode(payload).data};
    } else {
        const decodedText = new TextDecoder().decode(payload);
        try {
            return {event: type, data: JSON.parse(decodedText)};
        } catch (e) {
            return {event: type, data: decodedText};
        }
    }
}

/*export const compress = (payload: any) => {
    return gzipSync(new TextEncoder().encode(JSON.stringify(payload))).buffer;
}*/