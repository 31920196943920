import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { CookieModule } from 'ngx-cookie';
import { NgrxRouterStoreModule } from './router/ngrx-router.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AngularPageVisibilityModule } from 'angular-page-visibility';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatButtonModule } from '@angular/material/button';
import { IntercomModule } from 'ng-intercom';
import { reducers, metaReducers } from './store/reducers';
import { IntercomEffects } from './store/effects/intercom.effects';
import localeDa from '@angular/common/locales/da';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE, NativeDateModule } from '@angular/material/core';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
//import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
//import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { FunctionsModule, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getApp, getApps } from '@firebase/app';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TimeagoModule } from 'ngx-timeago';
import { RefreshAuthInterceptor } from './shared/interceptors/refresh-auth.interceptor';

registerLocaleData(localeDa, 'da');

@NgModule({
    declarations: [
        AppComponent,
        //UpdataDialogComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularPageVisibilityModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        NgrxRouterStoreModule,
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([AppEffects, IntercomEffects]),
        // AngularFireModule.initializeApp(environment.firebase),
        CookieModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        //MatDialogModule,
        MatButtonModule,
        TimeagoModule.forRoot(),
        IntercomModule.forRoot({
            appId: environment.intercomAppId,
            updateOnRouterChange: true
        }),
        NativeDateModule,
        FunctionsModule,
        NgxDaterangepickerMd.forRoot(),
        provideFirebaseApp(() => {
            const app = initializeApp(environment.firebase['viggo-drive-web-prod']);
            return app;
        }),
        provideFirebaseApp(() => {
            const app = initializeApp(environment.firebase['viggo-digitax-app'], 'viggo-digitax-app');
            app.automaticDataCollectionEnabled = false;
            app.options;
            return app;
        }),
        provideAuth(() => {
            const driveWeb = getApp();
            const auth = getAuth(driveWeb);
            return auth;
        }),
        provideAuth(() => {
            const digitaxApp = getApps().find(app => app.name === 'viggo-digitax-app');
            const auth = getAuth(digitaxApp);
            return auth;
        }),
        provideFirestore(() => {
            const digitaxApp = getApps().find(app => app.name === 'viggo-digitax-app');
            const firestore = getFirestore(digitaxApp);
            return firestore;
        }),
        provideStorage(() => {
            const digitaxApp = getApps().find(app => app.name === 'viggo-digitax-app');
            const storage = getStorage(digitaxApp);
            return storage;
        }),
        /*provideDatabase(() => {
          const database = getDatabase();
          return database;
        }),*/
        provideFunctions(() => {
            const digitaxApp = getApps().find(app => app.name === 'viggo-digitax-app');
            const functions = getFunctions(digitaxApp);
            return functions;
        })
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: LOCALE_ID, useValue: 'da' || navigator.language },
        { provide: MAT_DATE_LOCALE, useValue: 'da' || navigator.language },
        { provide: HTTP_INTERCEPTORS, useClass: RefreshAuthInterceptor, multi: true },
    ]
})
export class AppModule { }
