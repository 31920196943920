import { createReducer, on } from '@ngrx/store';
import { setDriverState, subscribeEvents, unsubscribeEvents, setDriver, setAreaId, logout, setTms, setAreas, setProductFeatures, setDispatchTags, setSocketStatus, setVehicleTypes } from './pages.actions';
import { DriverState } from '../shared/models';
import { setUser } from '../store/actions/app.actions';

export const pagesFeatureKey = 'pages';

const getCookie = (name) => {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
}

export interface State {
  driverState: DriverState;
  subscriptions: string[];
  // haulers: any[];
  driver: any;
  areas: any[];
  productFeatures: any;
  dispatchTags: any;
  vehicleTypes: any;
  selectedAreaId: string;
  tms: number;
  socketStatus: any;
  events: {
    'event-stream-id': string,
    flights: any[]
  };
}

export const initialState: State = {
  driverState: null,
  subscriptions: [],
  // haulers: null,
  driver: null,
  areas: null,
  productFeatures: {},
  vehicleTypes: {},
  dispatchTags: {},
  selectedAreaId: getCookie('area_id'),
  tms: null,
  socketStatus: null,
  events: {
    'event-stream-id': null,
    flights: null
  }
};


export const reducer = createReducer(
  initialState,

  on(setDriverState, (state, { data: { driverState } }) => ({ ...state, driverState })),
  // on(setHaulers, (state, { data: haulers }) => ({ ...state, haulers })),
  on(setDriver, (state, { data: driver }) => ({ ...state, driver })),
  on(setVehicleTypes, (state, { data: vehicleTypes }) => ({ ...state, vehicleTypes })),
  on(setUser, (state, { data: user }) => !user ? initialState : state),
  on(setAreaId, (state, { data: selectedAreaId }) => ({ ...state, selectedAreaId})),
  on(setTms, (state, { data: tms }) => ({ ...state, tms})),
  on(setAreas, (state, { data: areas }) => ({ ...state, areas})),
  on(setSocketStatus, (state, {data: socketStatus}) => ({...state, socketStatus})),
  on(setProductFeatures, (state, { data: productFeatures }) => ({ ...state, productFeatures})),
  on(setDispatchTags, (state, { data: dispatchTags }) => ({ ...state, dispatchTags})),
  on(logout, () => ({ ...initialState })),
  on(subscribeEvents, (state, { data }) => ({ ...state, subscriptions: [...new Set([...state.subscriptions, ...data])] })),
  on(unsubscribeEvents, (state, { data }) => {
    return { ...state, subscriptions: state.subscriptions.filter(s => !data.includes(s)) };
  })
);

